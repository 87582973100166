
import { defineComponent } from 'vue';
import AButton from '@/components/AButton.vue';
import { useAuth } from '@/services/auth';

export default defineComponent({
  components: {
    AButton,
  },
  setup() {
    const auth = useAuth();
    const signin = () => auth.loginWithRedirect();
    const logout = () => auth.logout({ returnTo: window.location.origin });

    return {
      authLoading: auth.loading,
      isAuthenticated: auth.isAuthenticated,
      signin,
      logout,
    };
  },
});
