<template>
  <div class="a-container"><slot /></div>
</template>

<style lang="scss" scoped>
.a-container {
  position: relative;
  max-width: 1400px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
</style>
