
import { defineComponent } from 'vue';
import {
  Lock as IconLock,
  Coins as IconCoins,
  Globe as IconGlobe,
  Heart as IconHeart,
} from '@vicons/fa';
import { RouteLocationRaw } from 'vue-router';
import AButton from '@/components/AButton.vue';
import AContainer from '@/components/AContainer.vue';
import TheNavbar from '@/components/TheNavbar.vue';
import RouteName from '@/router/RouteName';

export default defineComponent({
  components: {
    AContainer,
    AButton,
    IconLock,
    IconGlobe,
    IconCoins,
    IconHeart,
    TheNavbar,
  },
  setup() {
    const startRoute: RouteLocationRaw = { name: RouteName.Listener };
    return {
      startRoute,
    };
  },
});
