import {
  readonly,
  ref,
  shallowRef,
} from 'vue';
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  PopupConfigOptions,
  PopupLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import { Router } from 'vue-router';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './config';

export const RETURN_PATH_STORAGE_KEY = 'elna-return-path';

const auth0ClientOptions: Auth0ClientOptions = {
  redirect_uri: `${window.location.origin}/auth`,
  client_id: AUTH0_CLIENT_ID,
  domain: AUTH0_DOMAIN,
  audience: 'http://localhost:3000',
};

let initPromise: undefined | Promise<unknown>;

const loading = ref(true);
const isAuthenticated = ref<boolean | undefined>(undefined);
const user = ref<User | undefined>({});
const auth0Client = shallowRef<Auth0Client | undefined>();
const popupOpen = ref(false);
const error = ref<unknown>(undefined);

const initialize = async () => {
  if (initPromise) return initPromise;
  initPromise = new Promise((resolve) => {
    createAuth0Client(auth0ClientOptions).then((client) => {
      auth0Client.value = client;
      setTimeout(async () => {
        await auth0Client.value?.getTokenSilently().catch(() => { /* ignore */ });
        isAuthenticated.value = await auth0Client.value?.isAuthenticated() || false;
        user.value = await auth0Client.value?.getUser();
        loading.value = false;
        resolve(undefined);
      });
    });
  });
  return initPromise;
};

const forward = <
  F extends ((...args: any[]) => any) // eslint-disable-line @typescript-eslint/no-explicit-any
>(getTarget: () => F) => (...args: Parameters<F>) => initialize().then(() => getTarget()(...args));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, max-len
export const getTokenSilently = forward(() => auth0Client.value!.getTokenSilently.bind(auth0Client.value!));

const loginWithPopup = async (options?: PopupLoginOptions, config?: PopupConfigOptions) => {
  if (!auth0Client.value) {
    const err = new Error('No auth0 client');
    console.error(err);
    error.value = err;
    return;
  }
  popupOpen.value = true;

  try {
    await auth0Client.value.loginWithPopup(options, config);
    user.value = await auth0Client.value.getUser();
    isAuthenticated.value = await auth0Client.value.isAuthenticated();
    error.value = undefined;
  } catch (e) {
    error.value = e;
    console.error(e);
  } finally {
    popupOpen.value = false;
  }

  user.value = await auth0Client.value.getUser();
  isAuthenticated.value = true;
};

const handleRedirectCallback = async (params: { router: Router }) => {
  loading.value = true;
  let appState;
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const x = await auth0Client.value!.handleRedirectCallback();
    appState = x.appState;
    error.value = undefined;
  } catch (e) {
    error.value = e;
    console.error(e);
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  isAuthenticated.value = await auth0Client.value!.isAuthenticated();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  user.value = await auth0Client.value!.getUser();
  loading.value = false;
  if (!error.value) {
    params.router.replace(sessionStorage.getItem(RETURN_PATH_STORAGE_KEY) || '/');
  }
  return appState;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, max-len
export const loginWithRedirect = forward(() => auth0Client.value!.loginWithRedirect.bind(auth0Client.value!));
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, max-len
const getIdTokenClaims = forward(() => auth0Client.value!.getIdTokenClaims.bind(auth0Client.value!));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, max-len
const getTokenWithPopup = forward(() => auth0Client.value!.getTokenWithPopup.bind(auth0Client.value!));
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, max-len
const logout = forward(() => auth0Client.value!.logout.bind(auth0Client.value!));

export const useAuth = () => {
  if (!auth0Client.value) {
    initialize();
  }

  return {
    loading: readonly(loading),
    isAuthenticated: readonly(isAuthenticated),
    user: readonly(user),
    auth0Client: readonly(auth0Client),
    popupOpen: readonly(popupOpen),
    error: readonly(error),
    handleRedirectCallback,
    loginWithPopup,
    loginWithRedirect,
    getIdTokenClaims,
    getTokenSilently,
    getTokenWithPopup,
    logout,
  };
};
