<template>
  <div class="h-screen flex items-center justify-center text-center">
    <AContainer class="md:w-1/2 sm:w-3/4" v-if="error">
      <template v-if="error.type === 'verify_email'">
        <IconVerify class="h-24 mx-auto mb-6 opacity-50" />
        <h2 class="text-lg my-4">
          Zweryfikuj swój adres email
        </h2>
        <p class="text-sm mt-2 leading-normal">
          Zanim skorzystasz z El Niani po raz pierwszy zweryfikuj proszę swój adres email.
          Na adres <strong class="font-medium">{{ error.email }}</strong>
          wysłany został link który Ci to umożliwi.
        </p>
        <div class="mt-4">
          Nie to konto? <AButton text @click="login">Zaloguj się ponownie</AButton>
        </div>
      </template>
      <template v-else>
        <IconSad class="h-24 mx-auto mb-6 opacity-50" />
        <h2 class="text-lg my-4">
          Ups... Coś poszło nie tak
        </h2>
        <p class="text-sm mt-2 leading-normal">
          Spróbuj za chwilę jeszcze raz. Trzymam kciuki!
        </p>
        <div class="mt-4">
          <AButton to="/" text>Wróć do strony głównej</AButton>
        </div>
      </template>
    </AContainer>
    <div
      v-else
      style="border-top-color:transparent"
      class="w-16 h-16 border-4 border-green-600 border-dashed rounded-full animate-spin"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import {
  FrownRegular as IconSad,
  EnvelopeRegular as IconVerify,
} from '@vicons/fa';
import { useAuth } from '@/services/auth';
import AButton from '@/components/AButton.vue';
import AContainer from '@/components/AContainer.vue';

export default defineComponent({
  components: {
    AButton,
    AContainer,
    IconSad,
    IconVerify,
  },
  setup() {
    const auth = useAuth();
    const router = useRouter();
    onMounted(() => {
      const unwatch = watch(auth.auth0Client, (auth0Client) => {
        if (auth0Client) {
          auth.handleRedirectCallback({ router });
          nextTick(unwatch);
        }
      }, { immediate: true });
    });

    const error = computed(() => {
      if (!auth.error.value) return undefined;
      const message = !!auth.error.value && ((auth.error.value as Error).message || '');
      const verifyEmailMatch = message.match(/verify your email \((.*)\) before logging in/);
      if (verifyEmailMatch) {
        return {
          type: 'verify_email',
          email: verifyEmailMatch[1],
        };
      }
      return { type: 'generic' };
    });

    const login = () => auth.loginWithRedirect({ prompt: 'login' });

    return {
      error,
      login,
    };
  },
});
</script>
