
import { computed, defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: undefined,
    },
    large: Boolean,
    text: Boolean,
  },
  setup(props) {
    const tag = computed(() => (props.to ? 'router-link' : 'button'));
    return {
      tag,
    };
  },
});
