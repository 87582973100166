
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import {
  FrownRegular as IconSad,
  EnvelopeRegular as IconVerify,
} from '@vicons/fa';
import { useAuth } from '@/services/auth';
import AButton from '@/components/AButton.vue';
import AContainer from '@/components/AContainer.vue';

export default defineComponent({
  components: {
    AButton,
    AContainer,
    IconSad,
    IconVerify,
  },
  setup() {
    const auth = useAuth();
    const router = useRouter();
    onMounted(() => {
      const unwatch = watch(auth.auth0Client, (auth0Client) => {
        if (auth0Client) {
          auth.handleRedirectCallback({ router });
          nextTick(unwatch);
        }
      }, { immediate: true });
    });

    const error = computed(() => {
      if (!auth.error.value) return undefined;
      const message = !!auth.error.value && ((auth.error.value as Error).message || '');
      const verifyEmailMatch = message.match(/verify your email \((.*)\) before logging in/);
      if (verifyEmailMatch) {
        return {
          type: 'verify_email',
          email: verifyEmailMatch[1],
        };
      }
      return { type: 'generic' };
    });

    const login = () => auth.loginWithRedirect({ prompt: 'login' });

    return {
      error,
      login,
    };
  },
});
