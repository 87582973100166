<template>
  <nav
    class="z-10 w-full h-20 top-0 bg-gradient-to-b from-white"
    :class="{ fixed }"
  >
    <AContainer class="navbar__content">
      <div>

      </div>
      <router-link
        class="flex items-center text-xl text-green-900 font-medium cursor-pointer"
        to="/"
      >
        el <ElNannyLogo class="m-2" width="50" /> niania
      </router-link>

      <div class="flex justify-end">
        <AuthMenu class="sm:block hidden"/>
        <button @click="isDrawerOpen = !isDrawerOpen">
          <IconBars class="h-4 sm:hidden"/>
        </button>
      </div>
    </AContainer>
  </nav>
  <transition name="fade">
    <div
      v-if="isDrawerOpen"
      class="bg-black fixed right-0 z-10 h-full w-full bg-opacity-80 sm:hidden cursor-pointer"
      @click.self="isDrawerOpen = false"
    >
      <div class="bg-white h-full w-3/5 ml-auto cursor-auto">
        <button class="flex w-full px-5 pt-7 pb-4" @click="isDrawerOpen = false">
          <IconTimes class="h-4 ml-auto"/>
        </button>
        <AuthMenu class="text-center" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import {
  Bars as IconBars,
  Times as IconTimes,
} from '@vicons/fa';
import AContainer from '@/components/AContainer.vue';
import AuthMenu from '@/components/AuthMenu.vue';
import ElNannyLogo from '@/components/ElNannyLogo.vue';

export default defineComponent({
  components: {
    AContainer,
    AuthMenu,
    ElNannyLogo,
    IconBars,
    IconTimes,
  },
  props: {
    fixed: Boolean,
  },
  setup() {
    const isDrawerOpen = ref(false);
    return {
      isDrawerOpen,
    };
  },
});
</script>

<style lang="scss" scoped>
.navbar__content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;

  div {
    transition: transform 0.3s ease;
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  div {
    transform: translateX(100%);
  }
}
</style>
