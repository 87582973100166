
import { defineComponent, ref } from 'vue';
import {
  Bars as IconBars,
  Times as IconTimes,
} from '@vicons/fa';
import AContainer from '@/components/AContainer.vue';
import AuthMenu from '@/components/AuthMenu.vue';
import ElNannyLogo from '@/components/ElNannyLogo.vue';

export default defineComponent({
  components: {
    AContainer,
    AuthMenu,
    ElNannyLogo,
    IconBars,
    IconTimes,
  },
  props: {
    fixed: Boolean,
  },
  setup() {
    const isDrawerOpen = ref(false);
    return {
      isDrawerOpen,
    };
  },
});
