import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import AuthCallback from '../views/AuthCallback.vue';
import RouteName from './RouteName';
import {
  getTokenSilently,
  loginWithRedirect,
  RETURN_PATH_STORAGE_KEY,
} from '@/services/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth',
    name: 'AuthCallback',
    component: AuthCallback,
  },
  {
    path: '/listener',
    props: true,
    name: RouteName.Listener,
    component: () => import('../views/Listener.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/w/:namespace',
    props: true,
    name: RouteName.Watcher,
    component: () => import('../views/Watcher.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.requireAuth) return next();
  sessionStorage.setItem(RETURN_PATH_STORAGE_KEY, to.fullPath);
  getTokenSilently()
    .then(() => next())
    .catch(() => {
      loginWithRedirect();
    });
});

export default router;
