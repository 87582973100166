import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import 'vfonts/Inter.css';
import '@/assets/styles/main.scss';
import App from './App.vue';
import router from './router';

const app = createApp(App);

Sentry.init({
  release: process.env.VUE_APP_COMMIT_HASH,
  app,
  dsn: 'https://3f693658c6634442846f1a16bc231345@o1015200.ingest.sentry.io/5980595',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'elniania.rossinek.usermd.net', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(router).mount('#app');
