<template>
  <component
    :is="tag"
    :to="to"
    class="
      a-button
      font-medium
      rounded-lg
      inline-block
    "
    :class="{
      'text-base py-2 px-5': !large,
      'text-lg py-3 px-8': large,
      'text-white bg-green-500 shadow-md ring-green-500 ring-opacity-40': !text,
      'hover:bg-green-600 focus:ring-2 focus:outline-none active:ring-4': !text,
      'text-green-600 hover:text-green-500': text,
    }"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: undefined,
    },
    large: Boolean,
    text: Boolean,
  },
  setup(props) {
    const tag = computed(() => (props.to ? 'router-link' : 'button'));
    return {
      tag,
    };
  },
});
</script>
