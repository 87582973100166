<template>
  <TheNavbar fixed />

  <div class="hero">
    <div
      class="
        text-lg
        text-center
        p-8
        bg-gradient-to-r
        from-white
        flex
        items-center
        justify-center
        h-full
        w-full
        md:w-2/3
      "
    >
      <div>
        <h1 class="text-4xl font-medium text-green-500">
          Elektroniczna niania
        </h1>
        <p class="my-2">bezpieczna i całkowicie prywatna</p>
        <p class="my-2">korzysta z dowolnych urządzeń</p>
        <p class="my-2">bez instalowania aplikacji</p>
        <p class="my-2">stworzona przez rodzica dla rodziców</p>
        <div class="mt-6">
          <AButton :to="startRoute" large>Zaczynamy</AButton>
        </div>
      </div>
    </div>
  </div>

  <AContainer>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 my-6">
      <div class="rounded-lg shadow-md hover:shadow-xl cursor-pointer p-6">
        <div class="flex items-center">
          <div class="mr-2 p-2 flex items-center rounded-full bg-yellow-400">
            <div class="h-5 w-5 flex justify-center text-white">
              <IconLock />
            </div>
          </div>
          Prywatność
        </div>
        <div class="text-sm mt-3">
          Transmisja działa na zasadzie połączenia P2P (peer-to-peer) co oznacza,
          że nikt poza Tobą nie ma możliwości przechwycić nagrania.
        </div>
      </div>
      <div class="rounded-lg shadow-md hover:shadow-xl cursor-pointer p-6">
        <div class="flex items-center">
          <div class="mr-2 p-2 flex items-center rounded-full bg-green-400">
            <div class="h-5 w-5 flex justify-center text-white">
              <IconCoins />
            </div>
          </div>
          Ekonomia
        </div>
        <div class="text-sm mt-3">
          Aplikacja jest darmowa, a także nie wymaga zakupu żadnych dodatkowych urządzeń.
          Wystarczy że skorzystasz ze sprzętu który już masz w domu.
        </div>
      </div>
      <div class="rounded-lg shadow-md hover:shadow-xl cursor-pointer p-6">
        <div class="flex items-center">
          <div class="mr-2 p-2 flex items-center rounded-full bg-blue-400">
            <div class="h-5 w-5 flex justify-center text-white">
              <IconGlobe />
            </div>
          </div>
          Dostępność
        </div>
        <div class="text-sm mt-3">
          El niania jest dostępna z poziomu przeglądarki internetowej
          i nie wymaga instalowania żadnych dodatkowych aplikacji.
        </div>
      </div>
      <div class="rounded-lg shadow-md hover:shadow-xl cursor-pointer p-6">
        <div class="flex items-center">
          <div class="mr-2 p-2 flex items-center rounded-full bg-red-400">
            <div class="h-5 w-5 flex justify-center text-white">
              <IconHeart />
            </div>
          </div>
          Funkcjonalność
        </div>
        <div class="text-sm mt-3">
          Elektroniczna niania pozwoli Ci zająć się swoimi sprawami.
          Jeśli Twoje maleństwo będzie Cię potrzebować, nie tylko to usłyszysz, ale też zobaczysz.
        </div>
      </div>
    </div>

    <div class="my-12">
      <h2 class="text-2xl">Co potrzebujesz?</h2>
      <ul class="list-disc my-4 ml-8">
        <li class="my-2">
          Jedno urządzenie z kamerą, które zostawisz przy dziecku (np. telefon, tablet, laptop)
        </li>
        <li class="my-2">
          Drugie urządzenie, które zabierzesz ze sobą (np. telefon, tablet, laptop)
        </li>
        <li class="my-2">
          Dostęp do internetu
        </li>
      </ul>

      <h2 class="text-2xl mt-10">Jak to działa?</h2>
      <p class="my-4 leading-8">
        Po zalogowaniu się, otrzymasz możliwość rozpoczęcia transmisji.
        Kolejnym krokiem będzie dodanie urządzenia-kamery i już – gotowe!
        Elektroniczna video-niania zacznie działać.
      </p>
    </div>
  </AContainer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  Lock as IconLock,
  Coins as IconCoins,
  Globe as IconGlobe,
  Heart as IconHeart,
} from '@vicons/fa';
import { RouteLocationRaw } from 'vue-router';
import AButton from '@/components/AButton.vue';
import AContainer from '@/components/AContainer.vue';
import TheNavbar from '@/components/TheNavbar.vue';
import RouteName from '@/router/RouteName';

export default defineComponent({
  components: {
    AContainer,
    AButton,
    IconLock,
    IconGlobe,
    IconCoins,
    IconHeart,
    TheNavbar,
  },
  setup() {
    const startRoute: RouteLocationRaw = { name: RouteName.Listener };
    return {
      startRoute,
    };
  },
});
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
}

.navbar__content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.navbar__right {
  display: flex;
  justify-content: flex-end;
}

.hero {
  background-image: url('~@/assets/images/hero.png');
  background-size: cover;
  background-position: center bottom;
  height: 600px;
  display: flex;
  align-items: center;
}
</style>
